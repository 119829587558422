import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink, HttpLink } from 'apollo-link-http'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { endpointBase } from '@/common/constants/endpoints'
import { onError } from '@apollo/client/link/error'

// Install the vue plugin
Vue.use(VueApollo)

const url = `${endpointBase}/pimcore-graphql-webservices`

export type GQLClient = ApolloClient<NormalizedCacheObject>

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      // authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

const httpLink = new HttpLink({ uri: `${url}/app?apikey=${process.env.VUE_APP_GRAPHQL_APP_APIKEY}` })

const errorLink = onError(({ networkError }) => {
  // eslint-disable-next-line no-console
  console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', networkError?.message)
})

// Create the apollo client
export const appClient = new ApolloClient({
  link: ApolloLink.from([
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    errorLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

export const publicClient = new ApolloClient({
  link: createHttpLink({ uri: `${url}/public?apikey=${process.env.VUE_APP_GRAPHQL_PUBLIC_APIKEY}` }),
  cache: new InMemoryCache(),
})

export const apolloProvider = new VueApollo({
  defaultClient: appClient,
  clients: {
    public: publicClient,
  },
  defaultOptions: {
    $query: {
      // fetchPolicy: 'cache-and-network',
    },
  },
  errorHandler(error) {
    // eslint-disable-next-line no-console
    console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
  },
})
