/* eslint-disable @typescript-eslint/no-namespace */
import { appClient } from '@/plugins/vue-apollo.plugin'
import { rlog } from '@/common/utils/AppConsole'
import { Query } from '@/api/graphql/app/addressSuggest/AddressSuggestQuery'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'
import { Variables } from '@/api/graphql/app/addressSuggest/AddressQueryVariables'

export namespace GQLAddressSuggest {
  export namespace AddressSuggest {
    export const query = Query

    export type Data = {
      getAddress
    }

    export const execute = async (variables: Variables) =>
      appClient
        .query<Data>({ query, variables, fetchPolicy: 'no-cache' })
        .then(r => (r.data.getAddress.edges != null ? r.data.getAddress.edges.map() : []))
        .catch(e => rlog<string>('e', 'error', 'GQLAddressSuggest(L) exception', e))
  }

  export type Data = {
    getAddressSuggest
  }

  export type Type = VueGQLType<Data, Variables>
}
