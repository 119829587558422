
import Vue from 'vue'

import DoorConfigurator from '@/components/DoorConfigurator.vue' // @ is an alias to /src

export default Vue.extend({
  components: {
    DoorConfigurator,
  },
})
