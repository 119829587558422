import { useAddressSuggestRepository } from '@/api/graphql/app/addressSuggest/AddressSuggestRepository'

export const useAddressSuggestFetch = () => {
  const { suggestAddress } = useAddressSuggestRepository()

  const fetch = async (addressSuggest: string, addressType: string) => {
    return (await suggestAddress({ address: addressSuggest, type: addressType })).data.getAddress.edges
  }

  return {
    fetch,
  }
}
