
import Vue, { nextTick } from 'vue'
//local soource
import { GetRequest, PostInquiryApi } from '@/common/utils/api-func'
import FileDragDrop from '@/components/FileDragDrop.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import AddressSuggestInputForm from '@/components/form/AddressSuggestInputForm.vue'
import ZipSuggestInputForm from '@/components/form/ZipSuggestInputForm.vue'

// const CONF_DATA = require('../assets/configurator.json')
// const PRICE_DATA = require('../assets/prices.json')

const configuratorVersion = '1.046'
const VUE_APP_GRAPHQL_APIKEY = 'd013224b82e8e488a0437212c1dce984'
// const endpoint = `${process.env.VUE_APP_CONF_ENDPOINT}/configurator.json?apikey=${VUE_APP_GRAPHQL_APIKEY}&v=${configuratorVersion}`
const endpoint = `${process.env.VUE_APP_CONF_ENDPOINT}/configurator.json?apikey=${VUE_APP_GRAPHQL_APIKEY}&v=${configuratorVersion}`
const endpoint_price = `${process.env.VUE_APP_CONF_ENDPOINT}/prices.json?apikey=${VUE_APP_GRAPHQL_APIKEY}&v=${configuratorVersion}`

type cOption = {
  option: string
  children: []
  data: {
    pricingIDs: string[]
  }
  info: {
    image?: string
    help?: string
    discount?: {
      label: string
      active: boolean
    }
  }
}
type cName = {
  id: string
  name: string
  values: string | cName[] | cOption[]
  info: {
    image?: string
    help?: string
  }
}
type ConfData = {
  id: string
  name: string
  values: string
}

type priceItem = {
  name: string
  originalPrice: number
  compareAtPrice?: number
  labelPrice?: number
  additional: {
    disabled: boolean
    condition: string[]
    dependency: string
    subgroup: string[]
  }
  price: number
}

type finalPriceItems = {
  allowDesc: boolean
  priceEdit: boolean
  editDesc?: boolean
  key: string
  isDefault: boolean
  price: number
  originalPrice: number
  serviceName: string
  desc: string
}

type ContactData = {
  firstname: string
  lastname: string
  phone: string
  email: string
  city: string
  postcode: string
  note: string
}

type Attachment = {
  fileName: string
  mimeType: string
  data: string
}

interface DataFile64 {
  data: string
  name: string
  size: number
  type: string
}

type PostData = {
  contactData: ContactData
  configurations: {
    name: string
    parameters: DoorParameters
    additionalServices: AdditionalService[]
    attachments: Attachment[]
    priceItems: finalPriceItems[]
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cookies: any
}

type OrderItem = {
  orderNumber: string
  itemName: string
  totalPriceWithVat: string
}

type NewOrderResponseData = {
  order: OrderItem
}

type DoorParameters = {
  umisteni: string
  model: string
  vypln: string
  sirkaDveri: string

  // ------------------------------------

  moznostOtevirani: string
  umisteniPantu: string
  /** Special field: add to note-for-customer something */
  zdivo: string
  sirkaZarubne: string
  barva: string
  barvaZarubne: string

  kovani: string
  /** if (kovani == madlo) */
  kovaniData: {
    tvar: string
    delka: string
  }

  vzorVyplne: string
  typProskleni: string

  svetlik: string
  /** if (svetlik == ano) */
  svetlikData: {
    barva: string
    umisteni: string
    sirka: string
    typProskleni: string
  }

  aplikace: string
  /** if (aplikace == ano) */
  aplikaceData: {
    vzor: string
    strana: string
  }

  customerNoteZdivo: string
  customerNote: string

  jmenovky: string
  jmenovkyText: string
  jmenovkyData: {
    pismo: string
    ramecek: string
    tlapka: string
    typ: string
  }

  cisloNaDvere: string
  cisloNaDvereText: string
  cisloNaDvereData: {
    pismo: string
    ramecek: string
    typ: string
  }
}

type AdditionalService = {
  /**  */
  key: string
  name: string
  pcs: number

  /** original price WITHOUT VAT, passed from price configurator */
  originalPrice: number

  /** serves as DISPLAY PRICE */
  price: string

  /** Added as helper variable to determine whether the price was edited manually */
  manualMod?: boolean

  multi: boolean

  disabled: boolean

  dependency?: string
  condition?: string[]

  subgroup?: string[]

  /** @src/models/order/payload :: OrderConfigurationData - specialny field ktory indikuje ci ma vobec zaratavat tuto polozku */
  isOverlapping?: boolean

  dependencies?: {
    key: string
    value: string
  }[]
}

type ConfElement = {
  id: string
  key: string
  value: string
  level: number
  index: number
  data?: { pricingIDs: string[] }
}

enum OPTION_LABEL {
  hinge_left_conf = 'Levé panty, klika na pravé straně',
  hinge_left_app = 'Levé dveře',
  hinge_right_conf = 'Pravé panty, klika na levé straně',
  hinge_right_app = 'Pravé dveře',
  open_inside_conf = 'Otevírání dveří do bytu',
  open_inside_app = 'Otevírání dovnitř',
  open_outside_conf = 'Otevírání dveří do chodby',
  open_outside_app = 'Otevírání ven',
}

export default Vue.extend({
  data() {
    return {
      confData: {} as ConfData,
      customerData: {} as ContactData,
      postData: {} as PostData,
      postParameters: {} as DoorParameters,
      postServices: [{} as AdditionalService],
      level: 0,
      confItem: [{} as ConfElement],
      actionOnchange: [''],
      topOption: 0,
      priceData: {},
      priceList: {},
      doorPrice: 0,
      doorPriceTotal: 0,
      basePrice: 0,
      basePriceTotal: 0,
      priceNumber: 0,
      priceNumberTotal: 0,
      compareAtPriceNumber: 0,
      compareAtPrice: '',
      totalPrice: '',
      extrasPriceValue: 0,
      extrasPriceValueVat: 0,
      priceTags: {},
      extrasLeftColumn: '',
      extrasRightColumn: '',
      step: 0,
      files: [],
      isSvetlikVisible: false,
      isPremiumSelected: false,
      attachData: [{} as Attachment],
      attachPriceItems: [{} as finalPriceItems],
      isLoaderActive: false,
      filePickerState: '',
      gdpr: 'https://www.htdvere.cz/ochrana-osobnich-udaju-gdpr/',
      helpIco:
        '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="#39a169" d="m11 18h2v-2h-2zm1-16a10 10 0 0 0 -10 10 10 10 0 0 0 10 10 10 10 0 0 0 10-10 10 10 0 0 0 -10-10m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-14a4 4 0 0 0 -4 4h2a2 2 0 0 1 2-2 2 2 0 0 1 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5a4 4 0 0 0 -4-4z"/></svg>',
      svgIco:
        '<div class="checkIco w-8 h-8 p-2 flex items-center bg-white text-blue-700 rounded-full border-2 border-green-600"><svg data-v-a1ddd1cc="" xmlns="http://www.w3.org/2000/svg" width="17.611" height="13.91" viewBox="0 0 17.611 13.91" fill="currentColor" class="w-4 h-4"><path data-v-a1ddd1cc="" fill-rule="evenodd" clip-rule="evenodd" fill="#0067AD" d="M6.487 8.34L14.827 0l2.784 2.785L6.487 13.91 0 7.423l2.785-2.785L6.487 8.34z"></path></svg></div>',
      svgIcoDP:
        '<div class="checkIcoDP checkIco w-8 h-8 p-2 flex items-center bg-white text-blue-700 rounded-full border-2 border-green-600"><svg data-v-a1ddd1cc="" xmlns="http://www.w3.org/2000/svg" width="17.611" height="13.91" viewBox="0 0 17.611 13.91" fill="currentColor" class="w-4 h-4"><path data-v-a1ddd1cc="" fill-rule="evenodd" clip-rule="evenodd" fill="#0067AD" d="M6.487 8.34L14.827 0l2.784 2.785L6.487 13.91 0 7.423l2.785-2.785L6.487 8.34z"></path></svg></div>',
      vat: 1.12,
      modelSelect: '',
      foundPlacement: '',
      textRules: [v => (v || '')?.length <= 300 || 'Pozor! Maximum 300 znaků.'],
      priceNotes: {},
      doorPlateNameText: '',
      doorPlateNumberText: '',
      isDoorPlateName: false,
      isDoorPlateNumber: false,
      selectedMunicipality: '',
      selectedZip: '',
      focusedInput: '',
      resetModelSelection: false,
      updateUrlParams: false,
    }
  },
  methods: {
    onJmenovkyTextChange(newVal: string) {
      for (let i = 0; i < 10; ++i) {
        const el = document.getElementById(`jmenovkyTextSample${i}`) as HTMLDivElement
        if (!el) return
        el.innerText = newVal
      }
    },

    onDoorNumberChange(newVal: string) {
      for (let i = 0; i < 10; ++i) {
        const el = document.getElementById(`doorNumberTextSample${i}`) as HTMLDivElement
        if (!el) return
        el.innerText = newVal
      }
    },

    selectMunicipality(address) {
      this.customerData.city = address
      this.selectedMunicipality = this.customerData.city
    },

    onMunicipalityChanged(val) {
      this.selectedMunicipality = val
      this.customerData.city = val
    },

    onSelectZipCode(zip: string) {
      this.selectedZip = zip
    },

    scrollToForm() {
      const el = this.$refs['contactform']
      if (el) {
        nextTick(() => {
          el.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    nextPage() {
      if (this.isDoorPlateName && this.doorPlateNameText.length === 0) {
        alert('Vyplňte prosím text jmenovky na dveře.')
        return
      }
      if (this.isDoorPlateNumber && this.doorPlateNumberText.length === 0) {
        alert('Vyplňte prosím číslo na dveře.')
        return
      }
      ++this.step
      if (this.step === 1) this.scrollToForm()
      if (this.step > 1) this.step = 0
    },
    prevPage() {
      --this.step
      if (this.step < 0) this.step = 0
    },

    mul(a: number, b: number) {
      if (a === 0 || b === 0) return 0
      if (a === 1) return b
      if (b === 1) return a

      const r = a * b
      return r % 1 > 0.5 ? Math.ceil(r) : Math.floor(r)
    },

    formatPrice(price, currency) {
      let formatter
      if (currency) {
        formatter = new Intl.NumberFormat('cs-CS', {
          style: 'currency',
          currency: 'CZK',
          minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })
      } else {
        formatter = new Intl.NumberFormat('cs-CS', {
          style: 'decimal',
          minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })
      }
      return formatter.format(price)
    },

    // set door placement tab associated with selected model
    searchOptions(items: string | cOption[] | cName[], level: number, parent: string, interrupt: boolean, searchModel: string) {
      ++level

      if (!interrupt && items != undefined) {
        for (let i = 0; i < items.length; ++i) {
          let confHeader: cName = items[i] as cName
          if (confHeader.name != undefined) {
            this.searchOptions(confHeader.values, level, level === 1 ? confHeader.id : parent, interrupt, searchModel)
          } else {
            let confOption: cOption = items[i] as cOption
            if (confOption.option != undefined) {
              if (confOption.option.toLowerCase() === searchModel) {
                this.foundPlacement = parent
                interrupt = true
              } else {
                if (level === 1) {
                  if (this.foundPlacement.length === 0) this.topOption = i // parent precedes selected model
                }
                if (confOption.children != undefined)
                  this.searchOptions(confOption.children, level, level === 1 ? confOption.option : parent, interrupt, searchModel)
              }
            }
          }
        }
      }
    },

    // updateOptions(items: string | never[], level: number, parent: string) {
    updateOptions(items: string | cOption[] | cName[], level: number, parent: string) {
      //render subtree, find by id
      ++level
      let ret = ''
      let optionChildrenList = ''
      let hasChildren = false
      let optionId = ''
      let htmlExtras: string[] = []
      if (items != undefined) {
        //any children at this node
        for (const item of items) {
          let x = item as unknown as cOption
          if (x) {
            if (x.children != undefined) {
              hasChildren = true
            }
          }
        }

        for (let i = 0; i < items.length; ++i) {
          //check item type - name or option field
          let confHeader: cName = items[i] as cName
          if (confHeader.name != undefined) {
            optionId = confHeader.id

            //model
            if (level == 2) {
              const elementModel = document.getElementById(optionId) as HTMLDivElement
              if (elementModel) {
                elementModel.innerHTML = `<div class="modelItems">${this.updateOptions(confHeader.values, level, confHeader.id)}</div>`
              }
            } else {
              //radio button group
              let tipImg = ''
              if (confHeader.info && confHeader.info.help && confHeader.info.help.length > 0) {
                tipImg = `<span class="tippy-element"><span class="tippy-item" data-tippy-content='${confHeader.info.help}'>${this.helpIco}</span></span>`
              }
              let optionGroup = `<span class="option-caption text-green-600">${confHeader.name} ${tipImg}</span><div class="caption-group">${this.updateOptions(
                confHeader.values,
                level,
                confHeader.id
              )}</div>`

              if (optionId == 'prislusenstvi') {
                let el = document.getElementById('extrasLeftColumn') as HTMLDivElement
                if (el) {
                  el.innerHTML = this.extrasLeftColumn
                }
                el = document.getElementById('extrasRightColumn') as HTMLDivElement
                if (el) {
                  el.innerHTML = this.extrasRightColumn
                }
              } else {
                let el = document.getElementById(optionId) as HTMLDivElement
                if (el) {
                  el.innerHTML = optionGroup
                } else {
                  ret += optionGroup
                }
              }
            }
          } else {
            let confOption: cOption = items[i] as cOption
            if (confOption.option != undefined) {
              //let x: cOption = items[i]

              if (hasChildren) {
                const cmdId = `o_${level}_${i}_${parent}_${confOption.option}`

                //check dom el state, group el fo exclusive values
                let isSelected = i == 0
                if (level == 1) {
                  isSelected = this.topOption == i
                } else {
                  const elc = document.getElementById(cmdId) as HTMLInputElement
                  if (elc) {
                    isSelected = elc.checked
                    if (level === 3 && this.resetModelSelection) {
                      isSelected = i == 0
                      this.resetModelSelection = false
                    }
                  } else {
                    if (confOption.option === 'Ne' || confOption.option === 'Nechci světlík' || confOption.option === 'Nechci páskový dekor') {
                      isSelected = true
                    }
                    //default option No
                    if (confOption.option === 'Ano' || confOption.option === 'Chci světlík' || confOption.option === 'Chci páskový dekor') {
                      isSelected = false
                    }

                    // invoker param hook on model
                    if (this.modelSelect != undefined && this.modelSelect.length > 0 && level === 3) {
                      isSelected = this.modelSelect === confOption.option.toLowerCase()
                    }
                  }
                }
                this.confItem.push({ id: cmdId, key: parent, value: confOption.option, level: level, index: i, data: confOption.data })
                if (isSelected) {
                  ret += this.createOptionItem(confOption, cmdId, confOption.option, 'checked', level, parent)
                  //show children for selected item only
                  if (confOption.children != undefined) optionChildrenList = this.updateOptions(confOption.children, level, confOption.option)
                } else {
                  ret += this.createOptionItem(confOption, cmdId, confOption.option, '', level, parent)
                }

                if (confOption.data) {
                  this.priceTags[cmdId] = confOption.data.pricingIDs
                }
              } else {
                let cmdId = `o_${level}_${i}_${parent}_${confOption.option}`

                if (parent == 'prislusenstvi') {
                  let itemClass = 'option-item'
                  let counterElement = ''
                  let itemVisible = true
                  let helpText = ''

                  if (confOption.data) {
                    let groupName = ''
                    confOption.option = this.priceData[confOption.data.pricingIDs[0]].name
                    cmdId = `o_${level}_${i}_${parent}_${confOption.option}`
                    if (cmdId.includes('"')) cmdId = cmdId.replaceAll('"', '-') //kukatko "..."
                    this.priceTags[cmdId] = confOption.data.pricingIDs

                    let price = this.formatPrice(this.mul(this.priceData[confOption.data.pricingIDs[0]].originalPrice, this.vat), false)
                    let origPrice = Number(this.priceData[confOption.data.pricingIDs[0]].originalPrice)
                    let labelPrice = this.priceData[confOption.data.pricingIDs[0]].labelPrice
                      ? this.formatPrice(this.mul(this.priceData[confOption.data.pricingIDs[0]].labelPrice, this.vat), false)
                      : 0

                    if (this.priceData[confOption.data.pricingIDs[0]].additional) {
                      if (this.priceData[confOption.data.pricingIDs[0]].isOverlapping) {
                        const overlapValue = this.priceData[confOption.data.pricingIDs[0]].name
                        this.confItem.forEach(item => {
                          if (item.id.includes(overlapValue)) {
                            const elopt = document.getElementById(item.id) as HTMLInputElement
                            if (elopt) {
                              let elc = document.getElementById(cmdId) as HTMLInputElement
                              if (elc) {
                                elc.checked = elopt.checked
                              }
                            }
                          }
                        })
                      }

                      //count
                      if (this.priceData[confOption.data.pricingIDs[0]].additional.multi) {
                        let currentCount = 1
                        const elc = document.getElementById(`count_${cmdId}`) as HTMLInputElement
                        if (elc) {
                          currentCount = Number(elc.value)
                        }
                        price = price * currentCount
                        origPrice = origPrice * currentCount
                        counterElement = `<input class="input-box" type="number" id="count_${cmdId}" name="itemcount" min="1" step="1" value="${currentCount}">`
                        this.actionOnchange.push(`count_${cmdId}`)
                        this.priceList[this.priceTags[cmdId]].pcs = currentCount

                        // bind enabled to count
                        // let el = document.getElementById(cmdId) as HTMLInputElement
                        // if (el) {
                        //   if (Number(currentCount) > 1) el.checked = true
                        // }
                      }

                      //RULE: conditional visibility
                      if (
                        this.priceData[confOption.data.pricingIDs[0]].additional.dependency &&
                        this.priceData[confOption.data.pricingIDs[0]].additional.disabled
                      ) {
                        const depId = (
                          this.priceData[confOption.data.pricingIDs[0]].additional.dependency +
                          '_' +
                          this.priceData[confOption.data.pricingIDs[0]].additional.condition[0]
                        ).toLowerCase()

                        this.confItem.forEach(item => {
                          if (item.id.toLowerCase().includes(depId)) {
                            const controlElement = document.getElementById(item.id) as HTMLInputElement
                            itemVisible = !controlElement?.checked
                          }
                        })
                      }

                      if (
                        itemVisible &&
                        this.priceData[confOption.data.pricingIDs[0]].additional.dependencies &&
                        this.priceData[confOption.data.pricingIDs[0]].additional.disabled
                      ) {
                        const deps = this.priceData[confOption.data.pricingIDs[0]].additional.dependencies
                        for (let i = 0; i < deps.length; i++) {
                          const depId = (deps[i].key + '_' + deps[i].value).toLowerCase()
                          this.confItem.forEach(item => {
                            if (item.id.toLowerCase().includes(depId)) {
                              const controlElement = document.getElementById(item.id) as HTMLInputElement
                              if (controlElement?.checked) {
                                itemVisible = false
                                i = deps.length
                              }
                            }
                          })
                        }
                      }

                      // RULE: exclusive option, subgroup refers to sibling's, use property name to hold it
                      // sibling will be set off on click
                      if (this.priceData[confOption.data.pricingIDs[0]].additional.subgroup) {
                        groupName = `name="${this.priceData[confOption.data.pricingIDs[0]].additional.subgroup[0]}"`
                      }

                      if (this.priceData[confOption.data.pricingIDs[0]].additional.help) {
                        helpText = `<span class="tippy-element"><span class="tippy-item" data-tippy-content='${
                          this.priceData[confOption.data.pricingIDs[0]].additional.help
                        }'>${this.helpIco}</span></span>`
                      }
                    }

                    //preserve setting
                    let isSelected = false
                    {
                      let elc = document.getElementById(cmdId) as HTMLInputElement
                      if (elc) {
                        isSelected = elc.checked
                      }
                    }

                    let labelComparePrice = ''
                    labelComparePrice = labelPrice ? `<s class="inline-block text-red-600 pr-4" style="font-weight: 400">${labelPrice}&nbsp;Kč</s>` : ''

                    if (itemVisible) {
                      htmlExtras.push(
                        `<div class="${itemClass}"><label for="${cmdId}"><input class="rounded shadow" type="checkbox" id="${cmdId}" ${groupName} value="${
                          confOption.option
                        }" ${isSelected ? ' checked' : ''}><span class="ml-3">${
                          confOption.option
                        }</span></label>${helpText}<div class="pricing-col">${counterElement}${labelComparePrice}<span class="div-price"><span>${price}&nbsp;Kč</span></span></div></div>`
                      )
                    }
                  }
                } else {
                  //no children - radio options

                  if (confOption.data) {
                    this.priceTags[cmdId] = confOption.data.pricingIDs
                  }

                  //check associated image
                  let htmlImage = ''
                  let itemClass = 'option-item'
                  try {
                    if (confOption.info.image && confOption.info.image.length > 0) {
                      htmlImage = `<img id="i-${cmdId}" alt="" src="${confOption.info.image}">`
                    }
                    itemClass = `${itemClass}-image`
                    this.confItem.push({ id: `i-${cmdId}`, key: parent, value: confOption.option, level: level, index: i })
                  } catch (e) {
                    // print missing img names, add file to assets if need be
                  }

                  let isSelected = i == 0
                  {
                    const elc = document.getElementById(cmdId) as HTMLInputElement
                    if (elc) {
                      isSelected = elc.checked
                    }
                  }

                  // console.log(confOption.option, cmdId)
                  if (confOption.option === OPTION_LABEL.open_inside_app) {
                    confOption.option = OPTION_LABEL.open_inside_conf
                  } else if (confOption.option === OPTION_LABEL.open_outside_app) {
                    confOption.option = OPTION_LABEL.open_outside_conf
                  } else if (confOption.option === OPTION_LABEL.hinge_left_app) {
                    confOption.option = OPTION_LABEL.hinge_left_conf
                  } else if (confOption.option === OPTION_LABEL.hinge_right_app) {
                    confOption.option = OPTION_LABEL.hinge_right_conf
                  }

                  let checkedMask = this.svgIco
                  let selectedImageFrame = 'doorplateimage'
                  if (cmdId.includes('jmenovkyData.pismo')) {
                    htmlImage = `<div id="jmenovkyTextSample${i}" class="fontsamplename font${i}">${this.doorPlateNameText}</div>`
                    checkedMask = this.svgIcoDP
                  }

                  if (cmdId.includes('cisloNaDvereData.pismo')) {
                    htmlImage = `<div id="doorNumberTextSample${i}" class="fontsamplenumber fontnumber${i}">${this.doorPlateNumberText}</div>`
                    checkedMask = this.svgIcoDP
                    selectedImageFrame = 'doornumberimage'
                  }

                  if (htmlImage.length > 0) {
                    ret += `<div class="${itemClass}"><label for="${cmdId}"><div class="relative border-3 ${selectedImageFrame} ${
                      isSelected ? 'border-green-600' : 'border-transparent'
                    }">
                      ${htmlImage}
                      ${isSelected ? checkedMask : ''}
                      </div></label><div class="flex items-center mt-3"><input type="radio" id="${cmdId}" class="w-5 h-5 bg-white border border-blue-700 focus:outline-none" name="${parent}" ${
                      isSelected ? ' checked' : ''
                    } /><label for="${cmdId}" class="image-label"><span>${confOption.option}</span></label></div></div>`
                  } else {
                    ret += `<div class="${itemClass}">${htmlImage}<label for="${cmdId}"><input class="w-5 h-5 bg-white border border-blue-700 focus:outline-none" type="radio" id="${cmdId}" name="${parent}" value="${
                      confOption.option
                    }" ${isSelected ? ' checked' : ''}><span>${confOption.option}</span></label></div>`
                  }
                }
                this.confItem.push({ id: cmdId, key: parent, value: confOption.option, level: level, index: i, data: confOption.data })
              }
            }
          }
        }
        //umisteni
        if (level == 1) {
          const elementUmisteni = document.getElementById('umisteni') as HTMLDivElement
          if (elementUmisteni) {
            elementUmisteni.innerHTML = `${ret}`
          }
        }

        if (optionChildrenList.length > 0) {
          ret += optionChildrenList
          optionChildrenList = ''
        }

        //fill left and right columns for extras
        if (htmlExtras.length > 0) {
          for (let j = 0; j < htmlExtras.length; j++) {
            if (j >= htmlExtras.length / 2) {
              this.extrasRightColumn += htmlExtras[j]
            } else {
              this.extrasLeftColumn += htmlExtras[j]
            }
          }
        }
      }

      tippy('.tippy-item', {
        trigger: 'click',
        allowHTML: true,
        interactive: true,
        appendTo: document.body,
      })

      return ret
    },
    createOptionItem(confItem: cOption, id: string, caption: string, checked: string, level: number, parent: string) {
      let ret = ''
      //umisteni, tabs
      if (level == 1) {
        ret = `<button role="button" class="w-56 p-0 mb-0 mx-1 bg-white rounded-t-lg px-8 py-5 font-semibold text-lg text-center rounded-t-lg shadow-inner shadow-lg btnTab ${
          checked ? 'bg-blue-700 text-white' : 'text-blue-700'
        }" id="${id}">${caption}</button>`
      }
      //model, image and radio
      else if (level == 3) {
        //infer image id for click capture and resource name
        const imgId = `i-${id}`
        let imageElement
        let helpText
        if (confItem.info.image != undefined) {
          imageElement = `<img id="${imgId}" alt="" src="${confItem.info.image}" />`
        }

        if (confItem.info.help != undefined) {
          helpText = `<span class="help-text">${confItem.info.help}</span>`
        }

        if (id === 'o_3_0_model_Premium') {
          this.isPremiumSelected = !!checked
        }

        this.confItem.push({ id: imgId, key: parent, value: caption, level: level, index: 0 })
        ret = `<div class="modelItem w-1/2 lg:w-60 px-4 mb-5"><label for="${id}" class="flex relative w-full h-72 items-center justify-center bg-warmGray border-3 ${
          checked ? 'border-green-600' : 'border-transparent'
        }">
            ${
              confItem.info?.discount?.active
                ? `<div class="absolute -top-3.5 inset-x-0 text-center"><span class="bg-red-600 text-white font-semibold px-2 py-1 rounded">${confItem.info?.discount?.label}</span></div>`
                : ''
            }
            ${imageElement}
            ${checked ? this.svgIco : ''}
        </label><div class="modelItemCol"><div class="flex items-center"><input type="radio" id="${id}" class="w-5 h-5 bg-white border border-blue-700 focus:outline-none" name="${parent}" ${checked} /><label for="${id}" class="ml-4 font-semibold text-lg"><span>${caption}</span></label></div>${helpText}</div></div>`
      } else {
        let htmlImage = ''
        let itemClass = 'option-item'
        try {
          if (confItem.info.image && confItem.info.image.length > 0) {
            htmlImage = `<img id="i-${id}" alt="" src="${confItem.info.image}">`
          }
          itemClass = `${itemClass}-image`
        } catch (e) {
          // print missing img names, add file to assets if need be
        }
        let itemLabel = confItem.option
        if (confItem.option === 'Chci jmenovku') {
          itemLabel = `${confItem.option}<div class="doorPlatePrice">+399 Kč</div>`
        }
        if (confItem.option === 'Chci číslo') {
          itemLabel = `${confItem.option}<div class="doorPlatePrice">+199 Kč</div>`
        }

        if (htmlImage.length > 0) {
          ret = `<div class="${itemClass}"><label for="${id}"><div class="relative border-3 ${checked ? 'border-green-600' : 'border-transparent'}">
                      ${htmlImage}
                      ${checked ? this.svgIco : ''}
                      </div></label><div class="flex items-center mt-3"><input type="radio" id="${id}" class="w-5 h-5 bg-white border border-blue-700 focus:outline-none" name="${parent}" ${
            checked ? ' checked' : ''
          } /><label for="${id}" class="image-label"><span>${confItem.option}</span></label></div></div>`
        } else {
          ret = `<div class="${itemClass}">${htmlImage}<label for="${id}"><input class="w-5 h-5 bg-white border border-blue-700 focus:outline-none" type="radio" id="${id}" name="${parent}" value="${
            confItem.option
          }" ${checked ? ' checked' : ''}><span>${itemLabel}</span></label></div>`
        }
      }
      return ret
    },
    confItemOnChange() {
      this.loadConfiguration()
    },
    frameToDoorColorSync(sourceElement: HTMLInputElement) {
      if (sourceElement.id.includes('barva_')) {
        const doorFrameElement = document.getElementById(sourceElement.id.replace('barva_', 'barvaZarubne_')) as HTMLInputElement
        if (doorFrameElement) {
          doorFrameElement.checked = sourceElement.checked
        }
      }
    },
    confItemAction(event) {
      //decode level+index
      const arr = event.target.id.split('_')
      const elPrefix = arr[0]
      if (elPrefix == 'o' || elPrefix == 'i-o') {
        const level = Number(arr[1])
        const index = Number(arr[2])

        if (level === 1 || level === 3) {
          this.updateUrlParams = true
        }

        if (level === 1) {
          this.topOption = index
          this.resetModelSelection = true
        } else {
          const elInput = document.getElementById(event.target.id) as HTMLInputElement
          if (elInput) {
            if (elInput.type == 'radio') {
              elInput.checked = true
              this.frameToDoorColorSync(elInput)
            }
            if (elInput.type == 'checkbox') {
              //RULE: exclusive value, sibling's id in element name prop
              if (elInput.name) {
                for (const k in this.priceTags) {
                  if (this.priceTags[k] == elInput.name) {
                    const elc = document.getElementById(k) as HTMLInputElement
                    if (elc) {
                      elc.checked = false
                    }
                  }
                }
              }

              //RULE: is overlapping?
              const pricingIDs = this.priceTags[event.target.id]
              if (pricingIDs != undefined && this.priceData[pricingIDs].isOverlapping) {
                //find associated radio element by option value
                this.confItem.forEach(item => {
                  if (this.priceData[pricingIDs].name === item.value) {
                    const elopt = document.getElementById(item.id) as HTMLInputElement
                    if (elopt && elopt.type == 'radio') {
                      elopt.checked = elInput.checked
                      if (!elopt.checked) {
                        // get first sibling, by index: sibling.index === 0, or by different value: sibling.value !== item.value
                        this.confItem.forEach(sibling => {
                          if (sibling.key === item.key && sibling.value !== item.value) {
                            const siblingElement = document.getElementById(sibling.id) as HTMLInputElement
                            if (siblingElement) siblingElement.checked = true
                          }
                        })
                      }
                    }
                  }
                })
              }
            }
          }
        }

        //sync radio state on img click
        if (elPrefix == 'i-o') {
          const chId = event.target.id as string
          const radioElement = document.getElementById(chId.substring(2)) as HTMLInputElement
          if (radioElement) {
            radioElement.checked = true
            this.frameToDoorColorSync(radioElement)
          }
        }

        this.loadConfiguration()
      }
    },

    loadConfiguration() {
      this.priceTags = {}
      this.actionOnchange = []
      this.extrasLeftColumn = ''
      this.extrasRightColumn = ''
      this.priceNotes = {}

      let confItemOld = [...this.confItem]
      //clean listeners
      if (this.confItem.length > 0) {
        this.confItem.forEach(item => {
          const el = document.getElementById(item.id)
          el?.removeEventListener('click', this.confItemAction)
        })
        this.confItem = []
      }

      //reload page
      this.updateOptions(this.confData.values, 0, this.confData.name)

      //remove unused sections
      confItemOld.forEach(item => {
        if (!this.confItem.find(o => o.key === item.key)) {
          this.purgeElement(item.key)
        }
      })

      //svetlik - toggle section visibility
      const elsvet = document.getElementById('svetlik')
      if (elsvet) {
        elsvet.innerHTML.length > 0 ? (this.isSvetlikVisible = true) : (this.isSvetlikVisible = false)
      }

      //doorplate text fields visibility
      let doorPlateRadionElement = document.getElementById('o_9_1_jmenovky_Chci jmenovku') as HTMLInputElement
      if (doorPlateRadionElement && this.isDoorPlateName !== doorPlateRadionElement.checked) {
        this.isDoorPlateName = doorPlateRadionElement.checked
        this.doorPlateNameText = this.isDoorPlateName ? 'Ondřej Černý' : ''
        this.onJmenovkyTextChange(this.doorPlateNameText)
      }
      doorPlateRadionElement = document.getElementById('o_9_1_cisloNaDvere_Chci číslo') as HTMLInputElement
      if (doorPlateRadionElement && this.isDoorPlateNumber !== doorPlateRadionElement.checked) {
        this.isDoorPlateNumber = doorPlateRadionElement.checked
        this.doorPlateNumberText = this.isDoorPlateNumber ? '12' : ''
        this.onDoorNumberChange(this.doorPlateNumberText)
      }

      //load click listeners
      this.confItem.forEach(item => {
        let el = document.getElementById(item.id)
        el?.addEventListener('click', this.confItemAction)
      })
      for (const elId of this.actionOnchange) {
        let el = document.getElementById(elId)
        el?.addEventListener('change', this.confItemOnChange)
      }
      //count price
      let originalPrice = 0
      let price = 0
      let doorPrice = 0
      let doorPriceVat = 0
      let compareAtPrice = 0
      let urlParams = {}

      if (this.confItem.length > 0) {
        this.confItem.forEach(item => {
          const elc = document.getElementById(item.id) as HTMLInputElement
          if (elc?.checked) {
            if (item.data?.pricingIDs) {
              for (let i = 0; i < item.data?.pricingIDs?.length; ++i) {
                const pricingId = item.data?.pricingIDs[i]
                const pricingItem = this.priceList[pricingId]

                // Get only door price
                if (pricingId.includes('byt') || pricingId.includes('dom')) {
                  doorPrice += pricingItem.originalPrice
                  doorPriceVat += pricingItem.price
                }

                // disable behaviour of doubling Klika - klika price in summary view
                if (pricingItem.name === 'Klika - klika' && !pricingItem.additional) continue

                this.addPriceNoteValue(item.id, pricingId)

                // Round price with VAT 15%
                originalPrice += pricingItem.originalPrice * pricingItem.pcs
                price += pricingItem.price * pricingItem.pcs
                compareAtPrice += (pricingItem?.compareAtPrice ? pricingItem.compareAtPrice : pricingItem.price) * pricingItem.pcs
              }
            } else {
              const addedPrice = this.addPriceNote(item.id)
              // cihla price must be added extra, is singular case, no further checks required
              if (addedPrice > 0) {
                originalPrice += 1785.7
                price += 2000
                compareAtPrice += 2000
              }
            }
            if (item.key === 'model') {
              urlParams['placement'] = this.topOption === 0 ? 'byt' : 'dum'
              urlParams[item.key] = encodeURIComponent(item.value)
            }
          }
        })
        if (this.isDoorPlateName && this.isDoorPlateNumber) {
          this.priceNotes['cislonadvere_jmenovky'] = { title: 'jmenovku a číslo na dveře', price: 499 }
          originalPrice += 445.5
          price += 499
          compareAtPrice += 499
        } else if (this.isDoorPlateName) {
          this.priceNotes['jmenovky'] = { title: 'jmenovku', price: 399 }
          originalPrice += 356.3
          price += 399
          compareAtPrice += 399
        } else if (this.isDoorPlateNumber) {
          this.priceNotes['cislonadvere'] = { title: 'číslo na dveře', price: 199 }
          originalPrice += 177.7
          price += 199
          compareAtPrice += 199
        }
      }

      // Set door price
      this.doorPrice = doorPrice
      this.doorPriceTotal = doorPriceVat

      // Set whole price
      this.basePrice = originalPrice
      this.priceNumber = price
      this.totalPrice = this.formatPrice(price, true)
      this.compareAtPriceNumber = compareAtPrice
      this.compareAtPrice = this.formatPrice(compareAtPrice, true)

      if (this.updateUrlParams) {
        this.$router.replace({ path: '', query: urlParams })
        this.updateUrlParams = false
      }
    },
    addPriceNote(id: string): number {
      if (id.includes('kovani_Madlo')) {
        this.priceNotes['madlo'] = { title: 'madlo', price: 0 }
      } else if (id.includes('svetlik_Chci')) {
        this.priceNotes['svetlik'] = { title: 'světlík', price: 0 }
      } else if (id.includes('aplikace_Chci')) {
        this.priceNotes['aplikace'] = { title: 'páskový dekor', price: 0 }
      } else if (id.includes('zdivo_Cihla')) {
        this.priceNotes['cihla'] = { title: 'cihlové zdivo', price: 2000 }
        return 2000
      } else if (id.includes('zdivo_Zalitá zárubeň')) {
        this.priceNotes['zaruben'] = { title: 'zalitou zárubeň', price: 2000 }
        return 2000
      }
      return 0
    },
    addPriceNoteValue(id: string, pricingId: string) {
      if (id.includes('aplikace')) {
        this.priceNotes['aplikace'].price = this.mul(this.priceList[pricingId].originalPrice, this.vat)
      } else if (id.includes('svetlik')) {
        this.priceNotes['svetlik'].price = this.mul(this.priceList[pricingId].originalPrice, this.vat)
      } else if (id.includes('kovaniData')) {
        this.priceNotes['madlo'].price = this.mul(this.priceList[pricingId].originalPrice, this.vat)
      }
    },
    async imagesSelected() {
      return await Promise.all(
        this.files.map(f => {
          return this.readAsDataURL(f)
        })
      )
    },
    readAsDataURL(file) {
      return new Promise(resolve => {
        let fileReader = new FileReader()
        fileReader.onload = function () {
          return resolve({ data: fileReader.result, name: file.name, size: file.size, type: file.type })
        }
        fileReader.readAsDataURL(file)
      })
    },

    purgeElement(id: string) {
      const element = document.getElementById(id)
      if (element) element.innerHTML = ''
    },

    async sendConfigurationData() {
      const isValid = await this.$refs.obs.validate()

      // If form is valid
      if (isValid) {
        this.isLoaderActive = true
        this.customerData.postcode = this.selectedZip
        this.postData.contactData = this.customerData

        this.postParameters.umisteni = this.topOption === 0 ? 'Dveře do bytu' : 'Dveře do domu'
        // configuration radios
        this.confItem.forEach(item => {
          const el = document.getElementById(item.id) as HTMLInputElement
          if (el?.checked) {
            let itemVal = item.value
            if (itemVal === OPTION_LABEL.open_inside_conf) {
              itemVal = OPTION_LABEL.open_inside_app
            } else if (itemVal === OPTION_LABEL.open_outside_conf) {
              itemVal = OPTION_LABEL.open_outside_app
            } else if (itemVal === OPTION_LABEL.hinge_left_conf) {
              itemVal = OPTION_LABEL.hinge_left_app
            } else if (itemVal === OPTION_LABEL.hinge_right_conf) {
              itemVal = OPTION_LABEL.hinge_right_app
            }
            this.postParameters[item.key] = itemVal
            // console.log(item.value)
          }
        })

        this.postParameters.jmenovkyText = this.doorPlateNameText
        this.postParameters.cisloNaDvereText = this.doorPlateNumberText

        // extras, TODO unify data model
        let extrasPrice = 0
        let extrasPriceOriginal = 0
        this.postServices = []
        for (let k in this.priceTags) {
          const elc = document.getElementById(k) as HTMLInputElement
          if (elc) {
            if (elc.checked) {
              const arr = k.split('_')
              if (arr[3] === 'prislusenstvi') {
                const extraItem = { key: this.priceTags[k].toString(), ...this.priceList[this.priceTags[k]] }
                extrasPrice += this.priceList[this.priceTags[k]].price * this.priceList[this.priceTags[k]].pcs
                extrasPriceOriginal += this.priceList[this.priceTags[k]].originalPrice * this.priceList[this.priceTags[k]].pcs
                this.extrasPriceValue = extrasPriceOriginal
                this.extrasPriceValueVat = extrasPrice
                this.postServices.push(extraItem)
              }
            }
          }
        }

        // attachments
        this.attachData = []
        const images = (await this.imagesSelected()) as [DataFile64]
        for (let i = 0; i < images.length; i++) {
          let result = images[i].data.indexOf(';base64,')
          let data = images[i].data.substring(result + 8)
          this.attachData.push({ fileName: images[i].name, mimeType: images[i].type, data: data })
        }

        this.basePriceTotal = this.basePrice
        this.priceNumberTotal = this.priceNumber

        const doorPriceString = this.doorPrice
        const doorPriceVatString = this.doorPriceTotal
        const extrasPriceValueVatString = this.extrasPriceValueVat
        const extrasPriceValueString = this.extrasPriceValue

        // price items to backend
        this.attachPriceItems = [
          {
            allowDesc: false,
            priceEdit: false,
            key: 'totalPrice',
            isDefault: true,
            price: Number(this.priceNumberTotal),
            originalPrice: Number(this.basePriceTotal),
            serviceName: 'Celková cena',
            desc: '',
          },
          {
            allowDesc: false,
            priceEdit: true,
            key: 'baseprice',
            isDefault: true,
            price: Number(doorPriceVatString),
            originalPrice: Number(doorPriceString),
            serviceName: 'Cena dveří s montáží',
            desc: '',
          },
          {
            allowDesc: false,
            priceEdit: true,
            key: 'shipping',
            isDefault: true,
            price: 0,
            originalPrice: 0,
            serviceName: 'Cena dopravy',
            desc: '',
          },
          {
            allowDesc: false,
            priceEdit: false,
            key: 'additional',
            isDefault: true,
            price: Number(extrasPriceValueVatString),
            originalPrice: Number(extrasPriceValueString),
            serviceName: 'Doplňkové položky',
            desc: '',
          },
        ]

        // zdivo
        if (this.priceNotes['cihla']) {
          this.attachPriceItems.push({
            allowDesc: true,
            priceEdit: true,
            editDesc: false,
            key: 'zdivo_cihla',
            isDefault: true,
            price: 2000,
            originalPrice: 1785.7,
            serviceName: 'Vícepráce',
            desc: 'Příplatek za cihlové zdivo',
          })
        }
        if (this.priceNotes['zaruben']) {
          this.attachPriceItems.push({
            allowDesc: true,
            priceEdit: true,
            editDesc: false,
            key: 'zdivo_zalitazaruben',
            isDefault: true,
            price: 2000,
            originalPrice: 1785.7,
            serviceName: 'Vícepráce',
            desc: 'Příplatek za zalitou zárubeň',
          })
        }

        if (this.isDoorPlateName && this.isDoorPlateNumber) {
          this.attachPriceItems.push({
            allowDesc: true,
            priceEdit: true,
            editDesc: false,
            key: 'doorplate_name_number',
            isDefault: true,
            price: 499,
            originalPrice: 445.5,
            serviceName: 'Jmenovka a číslo',
            desc: 'Jmenovka a číslo',
          })
        } else if (this.isDoorPlateName) {
          this.attachPriceItems.push({
            allowDesc: true,
            priceEdit: true,
            editDesc: false,
            key: 'doorplate_name',
            isDefault: true,
            price: 399,
            originalPrice: 356.3,
            serviceName: 'Jmenovka',
            desc: 'Jmenovka',
          })
        } else if (this.isDoorPlateNumber) {
          this.attachPriceItems.push({
            allowDesc: true,
            priceEdit: true,
            editDesc: false,
            key: 'doorplate_number',
            isDefault: true,
            price: 199,
            originalPrice: 177.7,
            serviceName: 'Číslo',
            desc: 'Číslo',
          })
        }

        this.postData.configurations = {
          name: 'Konfigurace poptávky',
          parameters: this.postParameters,
          additionalServices: this.postServices,
          attachments: this.attachData,
          priceItems: this.attachPriceItems,
        }

        if (document.cookie) {
          this.postData.cookies = Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')))
        }

        const payload = JSON.stringify(this.postData)
        /**
         * Send data event to GTM
         */
        const dataLayer = (window.dataLayer = window.dataLayer || [])

        PostInquiryApi(process.env.VUE_APP_ENDPOINT, payload)().then(data => {
          const responseData = data as NewOrderResponseData
          dataLayer.push({
            event: 'generate_lead',
            value: responseData.order.totalPriceWithVat,
            currency: 'CZK',
            item_name: responseData.order.itemName,
            order_id: responseData.order.orderNumber,
          })

          // Send data layer event
          dataLayer.push({
            event: 'configuratorSubmitted',
            email: this.customerData.email,
            value: this.priceNumberTotal,
          })

          this.isLoaderActive = false
          this.step = 2
          window.setTimeout(() => {
            //clear all options
            this.topOption = 0
            this.purgeElement('svetlik')
            this.purgeElement('jmenovkyData.pismo')
            this.purgeElement('jmenovkyData.ramecek')
            this.purgeElement('jmenovkyData.tlapka')
            this.purgeElement('jmenovkyData.typ')
            this.purgeElement('cisloNaDvereData.pismo')
            this.purgeElement('cisloNaDvereData.ramecek')
            this.purgeElement('cisloNaDvereData.typ')
            this.doorPlateNameText = ''
            this.doorPlateNumberText = ''
            this.isDoorPlateName = false
            this.isDoorPlateNumber = false

            this.selectedMunicipality = ''
            this.selectedZip = ''

            this.confItem.forEach(item => {
              const el = document.getElementById(item.id) as HTMLInputElement
              if (el) el.remove()
            })
            this.confItem = []
            this.loadConfiguration()

            // clear customer form
            this.customerData = {} as ContactData
            this.files = []
            this.filePickerState = 'clear'
            this.step = 0
          }, 30000)
        })
      }
    },
    handleData: function (e) {
      ;[this.files] = e
    },
    getModelFromReferrer() {
      const referrer = document.referrer
      if (!referrer) {
        return
      }
      // first check for Premium EXT, then for Premium
      if (referrer.includes('premium-ext')) {
        this.modelSelect = 'premium ext'
      } else if (referrer.includes('premium')) {
        this.modelSelect = 'premium'
      } else if (referrer.includes('magnum-ext')) {
        this.modelSelect = 'magnum ext'
      } else if (referrer.includes('magnum')) {
        this.modelSelect = 'magnum'
      } else if (referrer.includes('perfect-68')) {
        this.modelSelect = 'perfect 68xl'
      }
      const flatModels = ['premium', 'magnum', 'magnum ext']
      const houseModels = ['premium ext', 'perfect 68xl', 'magnum ext']

      // if do domu, simulate a click on the second placement button
      if (referrer.includes('do-rodinnych-domu')) {
        const wrapper = document.querySelector('#umisteni')
        const button = wrapper?.children[1] as HTMLButtonElement
        if (button) button.click()

        const index = houseModels.indexOf(this.modelSelect)
        if (index < 0) {
          return
        }
        this.$nextTick(() => {
          // if do-bytu, simulate a click on the first placement button
          const wrapperModels = document.querySelectorAll('#model label.h-72')
          if (wrapperModels) {
            const activeModel = wrapperModels[index] as HTMLLabelElement
            activeModel.click()
          }
        })
      }

      if (referrer.includes('do-bytu')) {
        const wrapper = document.querySelector('#umisteni')
        const button = wrapper?.children[0] as HTMLButtonElement
        if (button) button.click()

        const index = flatModels.indexOf(this.modelSelect)
        if (index < 0) {
          return
        }
        this.$nextTick(() => {
          const wrapperModels = document.querySelectorAll('#model label.h-72')
          if (wrapperModels) {
            const activeModel = wrapperModels[index] as HTMLLabelElement
            activeModel.click()
          }
        })
      }
    },
    setParamsAsCookies() {
      const params = new URLSearchParams(window.location.search)

      for (const [key, value] of params.entries()) {
        // Setting each key-value pair as a cookie
        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; path=/`
      }
    },
  },

  components: {
    FileDragDrop,
    ValidationObserver,
    ValidationProvider,
    AddressSuggestInputForm,
    ZipSuggestInputForm,
  },

  created() {
    // test
    // this.modelSelect = 'perfect 68xl'
    // this.modelSelect = 'magnum'
    // this.modelSelect = window.modelSelectItem

    this.confItem = []
    this.setParamsAsCookies()
  },

  mounted() {
    this.customerData.email = '@'

    GetRequest(endpoint)().then(confdata => {
      //read prices
      GetRequest(endpoint_price)().then(data => {
        this.priceData = JSON.parse(JSON.stringify(data))
        // this.priceData = PRICE_DATA // For testing
        for (const [key, value] of Object.entries(this.priceData)) {
          const cont = value as priceItem
          cont.price = this.mul(cont.originalPrice, this.vat) // sans VAT
          if (cont.compareAtPrice) {
            cont.compareAtPrice = this.mul(cont.compareAtPrice, this.vat)
          }
          this.priceList[key] = { ...cont, pcs: 1 } // !copy object content
        }
        this.confData = JSON.parse(JSON.stringify(confdata))
        // this.confData = CONF_DATA // For testing

        // door model selection
        if (this.modelSelect != undefined && this.modelSelect.length > 0) {
          this.modelSelect = this.modelSelect.toLowerCase()
          this.searchOptions(this.confData.values, 0, '', false, this.modelSelect)
        }

        this.loadConfiguration()

        this.getModelFromReferrer()

        this.modelSelect = '' // model search just once
      })
    })
  },
})
