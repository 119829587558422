
import { defineComponent, ref, watch } from 'vue'
import { useAddressSuggestFetch } from '@/api/graphql/app/addressSuggest/AddressSuggestFetch'
import { SuggestAddress } from '@/models/suggestAddress/SuggestAddress'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    focused: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ValidationProvider,
  },
  emits: ['change', 'select', 'focus'],
  setup(props, { emit }) {
    const itemWidth = ref<string>(props.width)
    const localValue = ref<string>(props.value)
    const suggests = ref<SuggestAddress[]>([])
    const focused = ref<boolean>(props.focused)

    const { fetch } = useAddressSuggestFetch()

    const onFocus = (): void => {
      focused.value = true
      emit('focus', true)
    }

    const updateValue = async (val: string, disableSuggest = false) => {
      if (disableSuggest) {
        return
      }
      if (typeof val === 'string') localValue.value = val

      emit('change', val)

      if (val.toString().length < 1) {
        suggests.value = []
        return
      }

      suggests.value = await fetch(val.toString(), 'MUNICIPALITY_AND_DISTRICT')
    }

    const clear = (): void => {
      updateValue('')
      localValue.value = ''
    }

    const collapse = (): void => {
      suggests.value = []
    }

    const selectAddress = (addressObject): void => {
      const fullAddress =
        addressObject.municipality === addressObject.district ? addressObject.municipality : `${addressObject.municipality}, ${addressObject.district}`
      updateValue(fullAddress, true)

      suggests.value = []
      localValue.value = fullAddress

      emit('select', fullAddress)
    }

    const suggestItem = (item): string => {
      return item.municipality === item.district ? item.municipality : `${item.municipality}, ${item.district}`
    }

    watch(
      () => props.value,
      newValue => {
        localValue.value = newValue
      }
    )

    return {
      updateValue,
      localValue,
      itemWidth,
      suggests,
      clear,
      selectAddress,
      suggestItem,
      onFocus,
      collapse,
    }
  },
})
