import { Variables } from '@/api/graphql/app/addressSuggest/AddressQueryVariables'
import { apolloProvider } from '@/plugins/vue-apollo.plugin'
import { GQLAddressSuggest } from '@/api/graphql/app/addressSuggest/GQLAddressSuggest'

export const useAddressSuggestRepository = () => {
  return {
    suggestAddress: (searchAddress: Variables) =>
      apolloProvider.defaultClient.query({
        query: GQLAddressSuggest.AddressSuggest.query,
        variables: searchAddress,
        fetchPolicy: 'no-cache',
      }),
  }
}
