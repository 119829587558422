<template>
  <div id="file-drag-drop" @click="selectFile()">
    <form ref="fileform">
      <div class="drop-files">
        <div v-if="files.length > 0" class="file-listing">
          <div v-for="(file, key) in files" :key="key" class="file-listingItem">
            <img class="preview" alt="" v-bind:ref="'preview' + parseInt(key)" />
            <span>{{ file.name }}</span>
            <span class="remove" v-on:click.stop="removeFile(key)">
              <img src="../assets/img/x-mark-4-48.png" alt="" title="smazat" />
            </span>
          </div>
        </div>

        <div class="drop-files-content">
          <span>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m18 13.09c-.53.09-1.03.25-1.5.46v-7.55h1.5zm-5.5 8.41c-2.21 0-4-1.79-4-4v-12.5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1v-9.5h-1.5v9.5c0 1.38 1.12 2.5 2.5 2.5.21 0 .41-.03.6-.08.25-1.34.93-2.51 1.9-3.38v-9.54c0-2.21-1.79-4-4-4s-4 1.79-4 4v12.5c0 3.04 2.46 5.5 5.5 5.5.63 0 1.23-.11 1.79-.3-.32-.41-.59-.86-.79-1.34-.33.08-.66.14-1 .14m7.5-3.5v-3h-2v3h-3v2h3v3h2v-3h3v-2z"
              />
            </svg>
          </span>
          <h3>Vyberte nebo přetáhněte soubory</h3>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
    }
  },
  props: {
    filestate: String,
  },
  watch: {
    filestate: function (newVal) {
      // watch it
      if (newVal === 'clear') {
        this.files = []
        this.getImagePreviews()
      }
    },
  },
  methods: {
    determineDragAndDropCapable() {
      const div = document.createElement('div')
      return ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    },
    getImagePreviews() {
      for (let i = 0; i < this.files.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
          let reader = new FileReader()

          reader.addEventListener(
            'load',
            function () {
              this.$refs['preview' + parseInt(i)][0].src = reader.result
            }.bind(this),
            false
          )

          reader.readAsDataURL(this.files[i])
        } else {
          this.$nextTick(function () {
            this.$refs['preview' + parseInt(i)][0].src = require('@/assets/img/document-48.png')
          })
        }
      }
    },
    removeFile(key) {
      this.files.splice(key, 1)
      this.getImagePreviews()
      this.$emit('newdata', [this.files])
    },
    addFiles(newFiles) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i]

        // Check filetype extension
        if (!allowedExtensions.exec(file.name)) {
          alert('Chybný formát souboru. Povolené formáty: jpg, jpeg, png, pdf')
          return false
        }

        const first = this.files.find(obj => {
          return obj.name === file.name
        })

        if (first === undefined) {
          this.files.push(file)
        } else {
          alert(`Soubor ${first.name} byl už vybrán.`)
        }
      }
    },
    selectFile() {
      const input = document.createElement('input')
      input.type = 'file'
      input.multiple = 'multiple'
      input.onchange = e => {
        this.addFiles(e.target.files)
        this.getImagePreviews()
        this.$emit('newdata', [this.files])
      }
      input.click()
    },
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable()
    if (this.dragAndDropCapable) {
      ;['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault()
              e.stopPropagation()
            }.bind(this),
            false
          )
        }.bind(this)
      )

      this.$refs.fileform.addEventListener(
        'drop',
        function (e) {
          this.addFiles(e.dataTransfer.files)
          this.getImagePreviews()
          this.$emit('newdata', [this.files])
        }.bind(this)
      )
    }
  },
}
</script>

<!-- eslint-disable vue/require-v-for-key -->
<style lang="scss">
#file-drag-drop {
  width: 100%;
  height: 12rem;
  border: 3px dashed #d8d5d1;
  cursor: pointer;
  border-radius: 0.5rem;

  form {
    width: 100%;
    height: 100%;
  }
}

.drop-files {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.drop-files-content {
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 1rem;
    width: 2rem;
    display: inline-block;

    svg {
      width: 100%;
    }
  }
}

div.file-listing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  max-width: 100%;
  overflow-x: auto;
}

.file-listingItem {
  margin-right: 1.5rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  max-width: 170px;

  > img {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    margin-bottom: 0.25rem;
  }

  span {
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .remove {
    cursor: pointer;
    display: inline-block;
    margin-top: 0.25rem;

    img {
      height: 1.25rem;
    }
  }
}
</style>
