
import { defineComponent, ref, watch } from 'vue'
import { useAddressSuggestFetch } from '@/api/graphql/app/addressSuggest/AddressSuggestFetch'
import { SuggestAddress } from '@/models/suggestAddress/SuggestAddress'
import { ValidationProvider } from 'vee-validate'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    municipality: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    focused: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ValidationProvider,
  },
  emits: ['select', 'change'],
  setup(props, { emit }) {
    const itemWidth = ref<string>(props.width)
    const localValue = ref<string>(props.value)
    const suggests = ref<SuggestAddress[]>([])
    const focused = ref<boolean>(props.focused)

    const { fetch } = useAddressSuggestFetch()

    const findZipValue = async () => {
      focused.value = true
      if (props.municipality.length > 0) {
        emit('change', props.municipality)
        suggests.value = await fetch(props.municipality, 'ZIP')
        // console.log(suggests.value)
      }
    }

    const updateValue = async (val: string | number) => {
      if (typeof val === 'string') emit('select', val)
      if (val.toString().length < 1) {
        suggests.value = []
      }
    }

    const collapse = (): void => {
      suggests.value = []
    }

    const clear = (): void => {
      updateValue('')
      localValue.value = ''
    }

    const selectAddress = (addressObject): void => {
      const zipAddress = `${addressObject.zip}`

      suggests.value = []
      localValue.value = zipAddress

      emit('select', addressObject.zip)
    }

    watch(
      () => props.municipality,
      () => {
        suggests.value = []
        localValue.value = ''
      }
    )

    return {
      findZipValue,
      updateValue,
      localValue,
      itemWidth,
      suggests,
      clear,
      selectAddress,
      collapse,
    }
  },
})
