import { render, staticRenderFns } from "./AddressSuggestInputForm.vue?vue&type=template&id=35fad331&scoped=true&"
import script from "./AddressSuggestInputForm.vue?vue&type=script&lang=tsx&"
export * from "./AddressSuggestInputForm.vue?vue&type=script&lang=tsx&"
import style0 from "./AddressSuggestInputForm.vue?vue&type=style&index=0&id=35fad331&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fad331",
  null
  
)

export default component.exports