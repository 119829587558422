import Vue from 'vue'
// https://stackoverflow.com/questions/53588585/how-to-import-vuetify-lib-while-using-vuetify-loader-in-a-la-carte-system
// import Vuetify from 'vuetify'
// import Vuetify from 'vuetify/lib'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
//import '@mdi/font/css/materialdesignicons.css'
import { UserVuetifyPreset } from 'vuetify/types/services/presets'

Vue.use(Vuetify)

// https://vuetifyjs.com/en/features/presets/#default-preset
export const vuetifyPreset: Partial<UserVuetifyPreset> = {
  defaultAssets: false,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0066ff',
        transparent: '#ffffff',
      },
      dark: {},
    },
    options: {
      customProperties: true,
    },
  },
}

export default new Vuetify(vuetifyPreset)
