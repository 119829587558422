import gql from 'graphql-tag'

export const Query = gql`
  query GetAddress($address: String!, $type: String!) {
    getAddress(address: $address, type: $type) {
      totalCount
      edges {
        id
        number
        streetAndNumber
        street
        city
        municipality
        district
        region
        zip
        wholeAddress
      }
    }
  }
`
