import axios from 'axios'

export const GetRequest = <T>(url: string) => {
  return () =>
    new Promise<T | undefined>(resolve => {
      axios
        .get(url)
        .then(res => resolve(<T>res.data))
        .catch(e => {
          console.error(e)
          resolve(undefined)
        })
    })
}

export const PostInquiryApi = <T>(url: string, payload: never) => {
  return () =>
    new Promise<T | undefined>(resolve => {
      axios
        .post(url, payload, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(res => resolve(<T>res.data))
        .catch(e => {
          console.error(e)
          resolve(undefined)
        })
    })
}
